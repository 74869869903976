<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="평가이력"
        tableId="vendorAssessSeq"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        rowKey="vendorAssessSeq"
        @callbackProxy="callbackProxy"
      >
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vendor-assess-history',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: "totalGrade",
            field: "totalGrade",
            style: 'width:60px',
            label: "총점",
            align: "center",
            sortable: false,
          },
          {
            name: "evalTotalGrade",
            field: "evalTotalGrade",
            style: 'width:60px',
            label: "평점",
            align: "center",
            sortable: false,
          },
          {
            name: "evalGradeNm",
            field: "evalGradeNm",
            style: 'width:60px',
            label: "등급",
            align: "center",
            sortable: false,
          },
          {
            name: "evalYearYmd",
            field: "evalYearYmd",
            label: "평가기간",
            style: 'width:120px',
            align: "center",
            sortable: false,
            type: 'proxy',
            component: () => import(`${'./vendorAssessPop.vue'}`)
          },
          {
            name: "remark",
            field: "remark",
            label: "평가결과 종합",
            style: 'width:200px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          vendorAssessSeq: 1,
          evalGradeNm: 'A등급',
          evalYearYmd: "2021-01-25 ~ 2021-05-24",
          evalTotalGrade: "90점",
          totalGrade: '100점',
          remark: '평가결과 종합',
        },
        {
          vendorAssessSeq: 2,
          evalYearYmd: "2021-05-25 ~ 2021-08-14",
          evalGradeNm: 'A등급',
          evalTotalGrade: "80점",
          totalGrade: '100점',
          remark: '평가결과 종합',
        },
      ];
    },
    addItem() {
      this.grid.data.splice(0, 0, {
        vendorAssessSeq: 0,
        chkYmd: '',
        deptCd: '',
        chkResultDetail: '', 
        chkResult: '', 
        fileNm: '', 
      })
    },
    removeItem() {
      let selectData = this.$refs['hazardEquipmentInsp'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, item)
        })
      }
    },
    callbackProxy() {
      
    }
  }
};
</script>